@use '/src/domains/core/tamagoshiv2/tokens/spacings';
@import '@/core/tamagoshi/scss';
@import '../../../styles/commons';

.button {
  position: relative;

  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;

  @include size-above('md') {
    align-items: flex-end;

    &.link {
      justify-content: center;
    }
  }

  margin: 0;
  padding: 0 spacings.$s;

  cursor: pointer;
  text-align: left;
  text-decoration: none;

  font-family: inherit;
  white-space: nowrap;

  @include size-above('md') {
    max-width: 100%;
  }

  @include buttonDefault;

  &:hover {
    @include buttonHover;
  }

  &:focus {
    @include buttonFocus;
  }

  &:active {
    @include buttonPressed;
  }

  &:disabled {
    @include buttonDisabled;
  }

  &[aria-expanded='true'] {
    @include buttonSelected;

    border-radius: spacings.$s spacings.$s 0 0;
  }
}

.hideBelowMd {
  @include size-below('md') {
    display: none;
  }
}

.hideAboveMd {
  @include size-above('md') {
    display: none;
  }
}

.labelsContainer {
  display: flex;
  align-items: center;
  gap: 4px;

  @include size-above('md') {
    align-items: flex-end;
  }
}

.logo {
  @include size-above('md') {
    display: block;
    margin-bottom: spacings.$s;
    margin-top: 0;
  }
}

.anonymousLogo {
  @include size-below('md') {
    display: none;
  }
}

.labels {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;

  height: 40px;
}

.title {
  @include text('small', 'regular');
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 134px;
}

.descriptionContainer {
  display: inline-flex;
  align-items: flex-end;
}

.description {
  @include text('small', 'regular');
}

.icon {
  margin-left: spacings.$s;

  &.anonymous {
    @include size-above('md') {
      margin-bottom: spacings.$xs;
    }
  }
}
