@import '@/core/tamagoshi/scss';

$linksHeight: 50px;

.link {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;

  text-decoration: none;
  font-family: inherit;

  border-radius: $spacingXs;
  height: $linksHeight;
  padding: $spacingXs $spacingXs $spacingXs $spacingXXL1;

  background-color: $n0;

  &.b2c {
    background: $n0 url('img/logo.svg') no-repeat left $spacingXXs center;
  }

  &.b2b {
    background: $n0 url('img/logo-pro.svg') no-repeat left $spacingXXs center;
  }

  &:focus,
  &:hover {
    background-color: $n300;
  }
}

.title {
  @include text('small', 'semiBold');
  color: $n800;

  .link:focus &,
  .link:hover & {
    color: $b500;
  }
}

.description {
  @include text('small');
  color: $n700;

  .link:focus &,
  .link:hover & {
    color: $b500;
  }
}
