@use '/src/domains/core/tamagoshiv2/tokens/colors';

@import '@/core/tamagoshi/scss';
@import '@/domains/productDiscovery/HeaderLeonidas/styles/commons';

$dropdownMaxHeight: 220px;
$listMinWidth: 240px;

.container {
  position: relative;
}

.body {
  position: absolute;
  z-index: $headerLeonidasZIndexAccount;
  right: 0;
  top: 100%;
  max-height: 0;
  overflow: hidden;

  transition: max-height 250ms ease-out;

  &[data-expanded='true'] {
    max-height: $dropdownMaxHeight;
  }

  &:focus-within {
    max-height: $dropdownMaxHeight;
  }
}

.list {
  margin: 0;
  padding: $spacingXs;
  list-style: none;

  min-width: $listMinWidth;
  text-transform: none;

  background-color: $n0;
  border: 1px solid colors.$foundation-borderPrimary;
  border-radius: $spacingXs 0 $spacingXs $spacingXs;
}
